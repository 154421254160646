import {Component} from 'vue-property-decorator';
import MetadataEventUtil from "@/components/overlay/MetadataEventUtil";
import {JumpingTimingPayload, TestResultStatus, TimekeeperActionType, TimekeeperState} from "@/model";
import moment, {Moment} from "moment";
import {mixins} from "vue-class-component";
import CountryFlagUtil from "@/utils/countryiso";

@Component
export default class JumpingEventUtil extends mixins(MetadataEventUtil, CountryFlagUtil) {
    private firstEvent = true;
    protected hidden = true;
    private timer?: number;
    private startTime?: Moment;
    private clock?: Moment;
    private hideTimer = 0;

    get hasCombination() {
        return this.jumpingPayload && this.jumpingPayload.combination
    }

    get combination() {
        return this.jumpingPayload && this.jumpingPayload.combination
    }

    get totalErrors() {
        return this.jumpingPayload && this.jumpingPayload.totalErrors
    }

    private hasUnsuccessfulFinishStatus(payload: JumpingTimingPayload) {
        return payload.finishStatus == TestResultStatus.WITHDRAWN || payload.finishStatus == TestResultStatus.DISQUALIFIED || payload.finishStatus == TestResultStatus.ELIMINATED || payload.finishStatus == TestResultStatus.RETIRED
    }

    get timeStatus() {
        const payload = this.jumpingPayload
        if (!payload) {
            return ""
        }
        switch (payload.timekeeperState) {
            case TimekeeperState.COUNTDOWN:
                return (payload.totalTime! / 1000.0).toFixed(0)
            case TimekeeperState.RUNNING:
                return this.hasUnsuccessfulFinishStatus(payload) ? "_" : (payload.totalTime! / 1000.0).toFixed(0) + ".00"
            case TimekeeperState.FINISHED:
                return this.hasUnsuccessfulFinishStatus(payload) ? "_" : (payload.totalTime! / 1000.0).toFixed(2)
            case TimekeeperState.WAITING:
            default:
                return "&nbsp;"
        }
    }

    get errorStatus() {
        const payload = this.jumpingPayload
        if (!payload) {
            return ""
        }
        if (payload.timekeeperState == TimekeeperState.RUNNING || payload.timekeeperState == TimekeeperState.FINISHED) {
            switch (payload.finishStatus) {
                case TestResultStatus.RETIRED:
                    return "RET"
                case TestResultStatus.DISQUALIFIED:
                    return "DQ"
                case TestResultStatus.ELIMINATED:
                    return "EL"
                case TestResultStatus.WITHDRAWN:
                    return "WD"
                case TestResultStatus.NOT_STARTED:
                case TestResultStatus.FINISHED:
                default:
                    return payload.totalErrors
            }
        } else {
            return "&nbsp;"
        }
    }

    get showTimeAndErrors() {
        const payload = this.jumpingPayload
        if (!payload) {
            return false
        }
        return payload.timekeeperState == TimekeeperState.RUNNING || payload.timekeeperState == TimekeeperState.COUNTDOWN || payload.timekeeperState == TimekeeperState.FINISHED
    }

    private startTimer(startTime: Moment, clock?: Moment) {
        this.stopTimer()
        if (!this.timer) {
            this.startTime = startTime;
            this.clock = (clock || startTime).clone();
            this.timer = setInterval(() => {
                const now = this.clock!.add(100, "ms");
                let t = this.toMillisecondsAbsolute(now) - this.toMillisecondsAbsolute(this.startTime!) + this.jumpingPayload!.additionalTime! || 0
                if (this.jumpingPayload!.timekeeperState != TimekeeperState.COUNTDOWN) {
                    t = Math.max(0, t - 500) // skew a bit with the time to have a nice round-up when finishing
                }
                this.jumpingPayload!.totalTime = t;
            }, 100)
        }
    }

    private toMillisecondsAbsolute(t: Moment) {
        return ((t.hour() * 3600 + t.minute() * 60 + t.second()) * 1000) + t.millisecond();
    }

    private stopTimer() {
        clearInterval(this.timer)
        this.timer = undefined
    }


    get jumpingPayload() {
        if (this.metadataModule.event && this.metadataModule.event.type == "SHOW_JUMPING")
            return this.metadataModule.event.payload as JumpingTimingPayload
        else {
            return undefined
        }
    }

    private handleJumpingEvent(event: JumpingTimingPayload) {
        const clock = moment.unix(event.clock!);

        this.hidden = false
        clearTimeout(this.hideTimer)

        if (event.timekeeperState == TimekeeperState.COUNTDOWN || event.timekeeperState == TimekeeperState.RUNNING) {
            if (event.timekeeperAction == TimekeeperActionType.STARTED) {
                if (!this.firstEvent) {
                    this.startTimer(clock.clone().subtract(event.totalTime || 0, "ms"), clock);
                }
            }
            if (event.timekeeperAction == TimekeeperActionType.HALTED) {
                this.stopTimer();
            }
            if (event.timekeeperAction == TimekeeperActionType.CONTINUED) {
                if (!this.firstEvent) {
                    this.startTimer(clock.clone().subtract(event.totalTime || 0, "ms"), clock);
                }
            }

            if (event.timekeeperAction == TimekeeperActionType.UPDATE && !this.timer) {
                if (!this.firstEvent) {
                    this.startTimer(clock.clone().subtract(event.totalTime || 0, "ms"), clock);
                }
            }
        }

        if (event.timekeeperState == TimekeeperState.FINISHED || event.timekeeperAction == TimekeeperActionType.FINISHED) {
            this.stopTimer();
            this.autohide();
        }

        this.firstEvent = false
    }

    autohide() {
        this.hideTimer = setTimeout(() => {
            this.hidden = true
        }, 10000)
    }

    installEventHandler() {
        this.metadataModule.setEventHandler(event => {
            if(this.jumpingPayload) {
                this.handleJumpingEvent(this.jumpingPayload)
            }
        })
    }

    uninstallEventHandler() {
        this.stopTimer()
        this.metadataModule.setEventHandler(undefined)
    }

    get showTime() {
        return true
    }
}