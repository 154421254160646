















import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import videojs, {VideoJsPlayer} from 'video.js';
import "video.js/dist/video-js.css";
import 'videojs-overlay/dist/videojs-overlay.es';
import 'videojs-overlay/dist/videojs-overlay.css';
import 'videojs-playlist/dist/videojs-playlist.es';
import '@videojs/http-streaming/dist/videojs-http-streaming';
import '@/components/videojs-millicast'
import {getModule} from "vuex-module-decorators";
import {ApplicationModule} from "@/store/ApplicationModule";
import {MetadataModule} from "@/store/MetadataModule";
import {JumpingArenaActivityStatus, JumpingTimingPayload} from "@/model";

@Component
export default class EqifyVideoPlayer extends Vue {
  private applicationModule = getModule(ApplicationModule, this.$store)
  private metadataModule = getModule(MetadataModule, this.$store)

  @Ref("videoPlayer") private videoPlayerEl!: HTMLVideoElement;
  @Ref("metadataOverlay") private metadataOverlayEl!: HTMLVideoElement;
  @Ref("advOverlay") private advOverlayEl!: HTMLVideoElement;
  @Ref("arenaStatusOverlay") private arenaStatusOverlayEl!: HTMLVideoElement;
  private videoJsPlayer!: VideoJsPlayer;

  @Watch("applicationModule.advertising")
  private advertisingChanged(enabled: boolean) {
    enabled ? this.playAds() : this.stopAds()
  }

  mounted() {
    this.videoJsPlayer = videojs(this.videoPlayerEl, {
      poster: this.videoConfig!.options?.poster || "/img/default-poster.jpg",
      autoplay: true,
      aspectRatio: "16:9",
      controls: true,
      liveui: true,
      html5: {
        disablePictureInPicture: true
      },
      bigPlayButton: false,
      controlBar: {
        progressControl: false,
        playToggle: false,
        fullscreenToggle: !videojs.browser.IS_IPHONE,
        pictureInPictureToggle: false
      }
    })

    this.videoJsPlayer.ready(() =>
        this.videoJsPlayer.playsinline(true)
    )

    this.videoJsPlayer.overlay({
      debug: false,
      overlays: [
        {
          start: "metadata-show",
          end: "metadata-hide",
          content: this.metadataOverlayEl,
          class: 'eq-vjs-overlay',
          showBackground: false
        },
        {
          start: "adv-start",
          end: "adv-end",
          align: "bottom-right",
          content: this.advOverlayEl,
          class: 'eq-vjs-adv-overlay',
          showBackground: false
        },
        {
          start: "ready",
          content: this.arenaStatusOverlayEl,
          class: 'eq-vjs-overlay eq-vjs-arenastatus-overlay',
          showBackground: false
        }
      ]
    });

    if (this.videoConfig && this.videoConfig.type == "MILLICAST_WEBRTC") {
      this.videoJsPlayer.millicast({
        accountId: this.videoConfig.options!.accountId,
        streamName: this.videoConfig.options!.streamName
      })
    }


    this.videoJsPlayer.ready(() => {
      if (!this.applicationModule.hasConfig) {
        return
      }
      if (this.applicationModule.hasAds) {
        this.videoJsPlayer.on("ended", () => {
          if (this.videoJsPlayer.playlist.currentIndex() == this.videoJsPlayer.playlist.lastIndex()) {
            this.applicationModule.updateAdvertisingStatus(false)
          }
        })

        if (this.advertisingConfig!.enabled) {
          this.playAds()
        } else {
          this.playStream()
        }
      } else {
        this.playStream()
      }
    })
  }

  private playAds() {
    this.stopStream()
    const pl = this.advertisingConfig!.urls.map((url) => {
      return {
        sources: [
          {
            src: url,
            type: "video/mp4"
          }
        ]
      }
    })
    this.videoJsPlayer.playlist(pl)
    this.videoJsPlayer.playlist.autoadvance(0);
    this.videoJsPlayer.trigger("adv-start")
  }

  private stopAds() {
    this.videoJsPlayer.playlist([])
    this.videoJsPlayer.playlist.autoadvance()
    this.videoJsPlayer.pause()
    this.videoJsPlayer.trigger("adv-end")
    this.playStream()
  }

  private playStream() {
    this.videoJsPlayer.poster(this.videoConfig!.options?.poster || "/img/default-poster.jpg")
    this.videoJsPlayer.trigger("millicast-start")
    this.videoJsPlayer.trigger("metadata-show")
  }

  private stopStream() {
    this.videoJsPlayer.trigger("metadata-hide")
    this.videoJsPlayer.trigger("millicast-stop")
  }

  beforeDestroy() {
    if (this.videoJsPlayer) {
      this.videoJsPlayer.dispose()
    }
  }

  private get arenaStatus() {
    if (this.metadataModule.event && this.metadataModule.event.type == "SHOW_JUMPING") {
      return (this.metadataModule.event.payload as JumpingTimingPayload).arenaStatus
    } else {
      return undefined
    }
  }

  private arenaStatusDescription(arenaStatus: JumpingArenaActivityStatus) {
    switch (arenaStatus) {
      case JumpingArenaActivityStatus.BUILDING:
        return "Parcours bouwen"
      case JumpingArenaActivityStatus.EXPLORATION:
        return "Parcoursverkenning"
      case JumpingArenaActivityStatus.DRAGGING:
        return "Sleeppauze"
      case JumpingArenaActivityStatus.PAUSE:
        return "Pauze"
      case JumpingArenaActivityStatus.CLOSED:
        return "Afgesloten"
      default:
        return undefined
    }
  }

  private get videoConfig() {
    return this.applicationModule.config!.video
  }

  private get advertisingConfig() {
    return this.applicationModule.config!.advertising
  }
}
