import MetadataEventUtil from "@/components/overlay/MetadataEventUtil";
import {PresentationPayload} from "@/model";
import {Component} from "vue-property-decorator";

@Component
export default class PresentationEventUtil extends MetadataEventUtil {

    get hasPractitioner() {
        return this.presentationPayload && this.presentationPayload.practitioner
    }

    get practitioner() {
        return this.presentationPayload && this.presentationPayload.practitioner
    }

    get presentationPayload() {
        if (this.metadataModule.event && this.metadataModule.event.type == "PRESENTATION")
            return this.metadataModule.event.payload as PresentationPayload
        else {
            return undefined
        }
    }

    installEventHandler() {
        this.metadataModule.setEventHandler(() => {
            if (this.presentationPayload) {
                this.onEvent(this.presentationPayload)
            }
        })
    }

    uninstallEventHandler() {
        this.metadataModule.setEventHandler(undefined)
    }

    onEvent(event: PresentationPayload): void {
        // nothing to handle
    }
}