

































import {Component, Vue} from 'vue-property-decorator';
import CaptionBase from "@/components/overlay/CaptionBase.vue";
import {mixins} from "vue-class-component";
import DressageEventUtil from "@/components/overlay/dressage/DressageEventUtil";

@Component({
  components: {CaptionBase}
})
export default class DressageParticipant extends mixins(DressageEventUtil, Vue) {
  mounted() {
    this.installEventHandler()
  }

  beforeDestroy() {
    this.uninstallEventHandler()
  }
}
