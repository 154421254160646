import {Component} from 'vue-property-decorator';
import MetadataEventUtil from "@/components/overlay/MetadataEventUtil";
import {DressagePayload} from "@/model";
import CountryFlagUtil from "@/utils/countryiso";
import {mixins} from "vue-class-component";

@Component
export default class DressageEventUtil extends mixins(MetadataEventUtil, CountryFlagUtil) {

    get hasCombination() {
        return this.dressagePayload && this.dressagePayload.combination
    }

    get combination() {
        return this.dressagePayload && this.dressagePayload.combination
    }

    get dressagePayload() {
        if (this.metadataModule.event && this.metadataModule.event.type == "DRESSAGE")
            return this.metadataModule.event.payload as DressagePayload
        else {
            return undefined
        }
    }

    installEventHandler() {
        this.metadataModule.setEventHandler(event => {
            event.type == "DRESSAGE"
        })
    }

    uninstallEventHandler() {
        this.metadataModule.setEventHandler(undefined)
    }
}