
















import {Component, Vue} from 'vue-property-decorator';
import ApplicationStatusOverlay from "@/components/ApplicationStatusOverlay.vue";
import ScaledOverlay from "@/components/ScaledOverlay.vue";
import {getModule} from "vuex-module-decorators";
import {ApplicationModule} from "@/store/ApplicationModule";
import EqifyVideoPlayer from "@/components/EqifyVideoPlayer.vue";
import Background from "@/components/Background.vue";

@Component({
  components: {Background, EqifyVideoPlayer, ScaledOverlay, ApplicationStatusOverlay}
})
export default class App extends Vue {
  private applicationModule = getModule(ApplicationModule, this.$store)

  mounted() {
    const uri = window.location.search;
    const params = new URLSearchParams(uri);

    this.applicationModule.initialize({
      competition: params.get("c") || undefined,
      arena: params.get("a") || undefined,
      variant: params.get("v") || undefined
    })
  }

  get playerConfig() {
    return this.applicationModule.hasConfig && this.applicationModule.config
  }

  get isMetadataEnabled() {
    return this.playerConfig && this.playerConfig.metadata.type !== "NONE"
  }

  get isOnline() {
    return this.applicationModule.status == "ONLINE"
  }

  get isVideoEnabled() {
    return this.playerConfig && this.playerConfig.video.type !== "NONE"
  }
}
