










import {Component, Prop, Vue} from 'vue-property-decorator';
import {EmbeddedScaledOptions} from "@/model";
import OverlayLayout from "@/components/overlay/OverlayLayout.vue";

@Component({
  components: {OverlayLayout}
})
export default class ScaledOverlay extends Vue {
  @Prop({required: true}) options!: EmbeddedScaledOptions;
  private scale = 1

  mounted() {
    this.calculateScale(this.$el.clientWidth, this.$el.clientHeight)
  }

  get width() {
    return this.options.referenceWidth || 1920
  }

  get height() {
    return this.options.referenceHeight || 1080
  }

  handleResize(event: any) {
    this.calculateScale(event.width, event.height)
  }

  private calculateScale(width: number, height: number) {
    this.scale = Math.min(
        width / this.width,
        height / this.height
    )
  }
}
