








































import {Component, Vue, Watch} from 'vue-property-decorator';
import CaptionBase from "@/components/overlay/CaptionBase.vue";
import {mixins} from "vue-class-component";
import PresentationEventUtil from "@/components/overlay/presentation/PresentationEventUtil";
import {PresentationPayload} from "@/model";
import OverlayConfigMixin from "@/components/overlay/OverlayConfigMixin";

@Component({
  components: {CaptionBase}
})
export default class PresentationParticipant extends mixins(PresentationEventUtil, OverlayConfigMixin, Vue) {
  timeoutHandler = -1
  duration = 0
  showParticipant = true

  mounted() {
    this.installEventHandler()
  }

  beforeDestroy() {
    this.uninstallEventHandler()
  }

  @Watch("duration")
  durationWatch() {
    if (this.duration > 0) {
      this.showParticipant = true
      console.log("durationWatch.show")
      this.timeoutHandler = setTimeout(() => {
        console.log("durationWatch.timeout")
        this.duration = 0;
        this.showParticipant = false;
      }, this.duration * 1000)
    } else {
      console.log("durationWatch.clear")
      clearTimeout(this.timeoutHandler)
      this.showParticipant = false;
    }
  }

  onEvent(event: PresentationPayload) {
    this.duration = 0
    console.log("onEvent.1", event)
    if (event.practitioner) {
      this.duration = this.metadataParameters['timeout'] || 10
      console.log("onEvent.2", this.duration, this.showParticipant)
    } else {
      console.log("onEvent.3", this.showParticipant)
      this.showParticipant = false;
    }
  }
}
