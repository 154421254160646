



























































import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import JumpingEventUtil from "@/components/overlay/jumping/JumpingEventUtil";
import {TestResultStatus, TimekeeperState} from "@/model";

@Component
export default class CmhJumping extends mixins(JumpingEventUtil, Vue) {
  mounted() {
    this.installEventHandler()
  }

  beforeDestroy() {
    this.uninstallEventHandler()
  }

  get showPenalties() {
    const payload = this.jumpingPayload
    if (!payload) {
      return false
    }
    return payload.timekeeperState == TimekeeperState.RUNNING || (payload.timekeeperState == TimekeeperState.FINISHED && payload.finishStatus == TestResultStatus.FINISHED)
  }

  get showCountdown() {
    const payload = this.jumpingPayload
    if (!payload) {
      return false
    }
    return payload.timekeeperState == TimekeeperState.COUNTDOWN
  }

  get showStatus() {
    const payload = this.jumpingPayload
    if (!payload) {
      return false
    }
    return payload.finishStatus && payload.finishStatus !== TestResultStatus.NOT_STARTED && payload.finishStatus !== TestResultStatus.FINISHED
  }

  get statusLabel() {
    const payload = this.jumpingPayload
    if (!payload) {
      return ""
    }
    switch (payload.finishStatus) {
      case TestResultStatus.RETIRED:
        return "Retired"
      case TestResultStatus.DISQUALIFIED:
        return "Disqualified"
      case TestResultStatus.ELIMINATED:
        return "Eliminated"
      case TestResultStatus.WITHDRAWN:
        return "Withdrawn"
      case TestResultStatus.NOT_STARTED:
      case TestResultStatus.FINISHED:
      default:
        return ""
    }
  }

  get showLowerThird() {
    const payload = this.jumpingPayload
    if (!payload) {
      return false
    }
    return payload.timekeeperState && [TimekeeperState.WAITING, TimekeeperState.COUNTDOWN, TimekeeperState.FINISHED].includes(payload.timekeeperState)
  }
}

