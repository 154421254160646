import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {MetadataEvent, MetadataEventHandler} from "@/model";


export const EMPTY_EVENTHANDLER: MetadataEventHandler = event => console.debug("Not handling event ", event)

@Module({namespaced: true, name: 'MetadataModule'})
export class MetadataModule extends VuexModule {
    eventHandler?: MetadataEventHandler = EMPTY_EVENTHANDLER
    event?: MetadataEvent = {} as MetadataEvent

    @Action
    notification(event: MetadataEvent) {
        console.log("notification", event)
        if ((this.context.state as MetadataModule).eventHandler) {
            this.setLastEvent(event);
            (this.context.state as MetadataModule).eventHandler!(event)
        } else {
            EMPTY_EVENTHANDLER(event)
        }
    }

    @Mutation
    setLastEvent(event: MetadataEvent) {
        this.event = event
    }

    @Mutation
    setEventHandler(eventHandler?: MetadataEventHandler) {
        this.eventHandler = eventHandler
    }

    @Mutation
    clearEventHandler() {
        this.eventHandler = undefined
    }
}
