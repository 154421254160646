
















































import {Component, Vue} from 'vue-property-decorator';
import CaptionBase from "@/components/overlay/CaptionBase.vue";
import JumpingEventUtil from "@/components/overlay/jumping/JumpingEventUtil";
import {mixins} from "vue-class-component";

@Component({
  components: {CaptionBase}
})
export default class JumpingParticipant extends mixins(JumpingEventUtil, Vue) {
  mounted() {
    this.installEventHandler()
  }

  beforeDestroy() {
    this.uninstallEventHandler()
  }
}
