import Vue from 'vue'
import Vuex from 'vuex'
import {ApplicationModule} from '@/store/ApplicationModule'
import {MetadataModule} from "@/store/MetadataModule";
import {MetadataMessagingPlugin} from "@/store/MetadataMessagingPlugin";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        'ApplicationModule': ApplicationModule,
        'MetadataModule': MetadataModule,
    },
    plugins: [
        store => new MetadataMessagingPlugin(store)
    ]
});
