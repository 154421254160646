import {Component, Vue, Watch} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import {ApplicationModule} from "@/store/ApplicationModule";

@Component
export default class OverlayConfigMixin extends Vue {
    protected applicationModule = getModule(ApplicationModule, this.$store)

    get hasMetadata() {
        return this.applicationModule.config && this.applicationModule.config.metadata
    }

    get hasMetadataParameters() {
        return this.hasMetadata && this.applicationModule.config!.metadata.options!.parameters
    }

    get metadataParameters() {
        if (this.applicationModule.config && this.applicationModule.config.metadata.options && this.applicationModule.config.metadata.options.parameters) {
            return this.applicationModule.config.metadata.options.parameters
        } else {
            return {}
        }
    }

    metadataOptions() {
        return this.applicationModule.config!.metadata.options!!
    }

    get assets() {
        if (this.hasMetadata) {
            return this.metadataOptions().assets || []
        } else {
            return []
        }
    }

    get showCustomerLogo() {
        if (this.hasMetadataParameters) {
            return this.metadataParameters["hideCustomerLogo"] !== 1
        } else {
            return true
        }
    }

    get showEqifyLogo() {
        if (this.hasMetadataParameters) {
            return this.metadataParameters["hideEqifyLogo"] !== 1
        } else {
            return true
        }
    }

    get showArena() {
        if (this.hasMetadataParameters) {
            return this.metadataParameters["hideArena"] !== 1
        } else {
            return true
        }
    }

    get showTitle() {
        if (this.hasMetadataParameters) {
            return this.metadataParameters["hideTitle"] !== 1
        } else {
            return true
        }
    }
}