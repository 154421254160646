






import {Component, Prop, Vue} from 'vue-property-decorator';
import {EmbeddedScaledOptions} from "@/model";

@Component
export default class SponsorBar extends Vue {
  @Prop({required: true, type: Array, default: () => []}) sponsors!: Array<string>;

}
