











import {Component, Prop, Vue} from 'vue-property-decorator';
import {EmbeddedLayoutName, EmbeddedScaledOptions} from "@/model";
import JumpingParticipant from "@/components/overlay/jumping/JumpingParticipant.vue";
import CaptionBase from "@/components/overlay/CaptionBase.vue";
import DressageParticipant from "@/components/overlay/dressage/DressageParticipant.vue";
import CmhJumping from "@/components/overlay/cmh/CmhJumping.vue";
import CmhDressage from "@/components/overlay/cmh/CmhDressage.vue";
import PresentationParticipant from "@/components/overlay/presentation/PresentationParticipant.vue";


function removeClassStartsWith(element: Element, prefix: string) {
  for (let i = element.classList.length - 1; i >= 0; i--) {
    if (element.classList[i].startsWith(prefix)) {
      element.classList.remove(element.classList[i]);
    }
  }
}

@Component({
  components: {PresentationParticipant, CmhDressage, CmhJumping, DressageParticipant, CaptionBase, JumpingParticipant}
})
export default class OverlayLayout extends Vue {
  @Prop({required: true}) options!: EmbeddedScaledOptions;

  mounted() {
    this.applyTheme(this.options.theme)
  }

  private applyTheme(theme: string) {
    const appElement = this.$el;

    // Wipe theme
    removeClassStartsWith(appElement, "theme-")
    removeClassStartsWith(appElement, "variant-")

    const themeName = theme ? "theme-" + theme : "theme-default"
    // const variant = config.variant ? "variant-" + config.variant : "variant-dark"
    appElement.classList.add(themeName)
    // appElement.classList.add(variant)
    // console.log("Using theme " + theme)
  }

  layoutIs(name: EmbeddedLayoutName) {
    return this.options.layout === name
  }
}

