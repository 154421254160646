import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {PlayerConfig} from "@/model";
import axios from 'axios'


@Module({namespaced: true, name: 'ApplicationModule'})
export class ApplicationModule extends VuexModule {
    loading = true
    status: ApplicationStatus = "UNKNOWN"
    advertising = false
    videoStatus: VideoStatus = "UNKNOWN"
    metadataStatus: MetadataStatus = "UNKNOWN"
    config: PlayerConfig | undefined = {} as PlayerConfig;

    get hasConfig() {
        return this.config && this.config.context
    }

    get hasAds() {
        return (this.config && this.config.advertising && this.config.advertising.urls && this.config.advertising.urls.length > 0) || false
    }

    @MutationAction
    async initialize(request: InitRequest) {
        let config: PlayerConfig | undefined;
        let status: ApplicationStatus;
        let advertising = false;
        try {
            config = (await axios.get("/api/config", {
                params: {
                    c: request.competition,
                    a: request.arena,
                    v: request.variant
                }
            })).data
            status = config && config.online ? "ONLINE" : "OFFLINE"
            advertising = (config && config.advertising && config.advertising.enabled) || false
        } catch (e) {
            status = "NOT_FOUND"
        }
        return {config: config, status: status as ApplicationStatus, advertising: advertising, loading: false}
    }

    @MutationAction
    async updateConfig(config?: PlayerConfig) {
        return {
            config: config,
            status: config && (config.online ? "ONLINE" : "OFFLINE") as ApplicationStatus,
            advertising: config && config.advertising && config.advertising.enabled,
            loading: false
        }
    }

    @Mutation
    updateApplicationtatus(status: ApplicationStatus) {
        this.status = status
    }

    @Mutation
    updateAdvertisingStatus(enabled: boolean) {
        this.advertising = enabled
        this.config!.advertising!.enabled = enabled
        console.log("updateAdvertisingStatus", status)
    }

    @Mutation
    updateVideoStatus(status: VideoStatus) {
        this.videoStatus = status
    }

    @Mutation
    updateMetadataStatus(status: MetadataStatus) {
        this.metadataStatus = status
    }

    @Mutation
    setLoading(loading = true) {
        this.loading = loading
    }
}

export type ApplicationStatus = "UNKNOWN" | "ONLINE" | "OFFLINE" | "ONLINE_ERROR" | "ERROR" | "NOT_FOUND"
export type VideoStatus = "UNKNOWN" | "NO_VIDEO" | "PLAYING" | "BUFFERING" | "ERROR"
export type MetadataStatus = "UNKNOWN" | "CONNECTED" | "ERROR"

export interface InitRequest {
    competition?: string;
    arena?: string;
    variant?: string;
}