import {Store} from "vuex";
import webstomp, {Client, Frame, Headers} from "webstomp-client";
import {
    Arena,
    Competition,
    MetadataBrokerConfig,
    MetadataEventPayload,
    MetadataEventType,
    PlayerConfig,
    Test
} from "@/model";
import {ApplicationModule} from "@/store/ApplicationModule";
import {MetadataModule} from "@/store/MetadataModule";
import {getModule} from "vuex-module-decorators";

const RECONNECT_TIMEOUT = 2000
const DEFAULT_MESSAGE_DELAY = 3000

export class MetadataMessagingPlugin {
    private applicationModule?: ApplicationModule;
    private metadataModule?: MetadataModule;
    private options?: MetadataBrokerConfig
    private client?: Client
    private retryLoop?: number
    private messageTimeouts: Array<number> = []

    constructor(store: Store<any>) {
        this.applicationModule = getModule(ApplicationModule, store)
        this.metadataModule = getModule(MetadataModule, store)
        store.subscribe((mutation, state) => {
            if (mutation.type == "ApplicationModule/initialize") {
                const config = (mutation.payload as ApplicationModule).config
                this._disconnect()
                if (config && config.metadata.type !== "NONE" && config.metadata.broker) {
                    this.options = config.metadata.broker
                    this._connect()
                }
            }
        })
    }

    dispose() {
        this._disconnect()
        this.options = undefined
    }

    private _connect() {
        if (this.retryLoop) {
            clearTimeout(this.retryLoop)
        }
        const ws = new WebSocket(this.options!.url);
        this.client = webstomp.over(ws, {debug: true})
        this.client.connect(this.options!.user || "", this.options!.password || "",
            () => this.connected(),
            () => this.retryLoop = setTimeout(this._connect.bind(this), RECONNECT_TIMEOUT),
            this.options!.vhost || "/"
        )
    }

    private _disconnect() {
        if (this.retryLoop) {
            clearTimeout(this.retryLoop)
        }
        if (this.client && this.client.connected) {
            this.client.disconnect()
        }
        this.client = undefined
    }

    private connected() {
        if (this.client) {
            this.sayHello()
            this.client.subscribe(`/exchange/eqify.player/${this.keyPrefix}.online`, message => {
                this.applicationModule!.updateApplicationtatus(message.body == "true" ? "ONLINE" : "OFFLINE")
            })
            this.client.subscribe(`/exchange/eqify.player/${this.keyPrefix}.ads`, message => {
                this.applicationModule!.updateAdvertisingStatus(message.body == "true")
            })
            this.client.subscribe(`/exchange/eqify.player/${this.keyPrefix}.config`, message => {
                this.cancelPendingMessages()
                this.applicationModule!.updateConfig(JSON.parse(message.body) as PlayerConfig)
            })
            this.client.subscribe(`/exchange/eqify.player/${this.keyPrefix}.event`, message => {
                this.triggerMessage(message.body, message.headers)
            })
        }
    }

    private sayHello() {
        (this.client! as any)["onreceive"] = (frame: Frame) => this.handleRawMessage(frame.body, frame.headers)
        this.client!.send(
            '/exchange/eqify.player/hello',
            JSON.stringify({
                competition: this.applicationModule!.config!.context.competition.uuid,
                arena: this.applicationModule!.config!.context.arena.uuid
            }),
            {
                'reply-to': '/temp-queue/hello',
            })
    }

    private cancelPendingMessages() {
        this.messageTimeouts.map(it => clearTimeout(it))
        this.messageTimeouts = []
    }

    private triggerMessage(body: string, headers: Headers) {
        const delay = this.applicationModule!.config!.metadata.delay || DEFAULT_MESSAGE_DELAY
        const t = setTimeout(() => {
            this.handleRawMessage(body, headers)
            this.messageTimeouts.splice(this.messageTimeouts.indexOf(t))
        }, delay)
        this.messageTimeouts.push(t)
    }

    private handleRawMessage(body: string, headers: Headers) {
        const payload = JSON.parse(body)
        console.log("handleRawMessage", body, payload)
        if (this.ignoreMessage(payload, headers)) {
            // ignore message
            return
        }
        this.metadataModule!.notification({
            type: headers["x-eq-event-type"] as MetadataEventType,
            competition: {
                uuid: headers["x-eq-competition-uuid"],
                name: headers["x-eq-competition-name"],
            } as Competition,
            arena: headers["x-eq-arena-uuid"] ? {
                uuid: headers["x-eq-arena-uuid"],
                name: headers["x-eq-arena-name"] ? headers["x-eq-arena-name"] : headers["x-eq-arena-uuid"],
            } as Arena : undefined,
            test: headers["x-eq-test-uuid"] ? {
                uuid: headers["x-eq-test-uuid"],
                name: headers["x-eq-test-name"] ? headers["x-eq-test-name"] : headers["x-eq-test-uuid"],
            } as Test : undefined,
            payload: payload
        })
    }

    private ignoreMessage(body: MetadataEventPayload, headers: Headers) {
        return headers["notfound"] !== undefined
    }

    private get keyPrefix() {
        return `${this.applicationModule!.config!.id}`
        // return `${this.applicationModule!.config!.context.competition.uuid}.${this.applicationModule!.config!.context.arena.uuid}`
    }
}
