import videojs, {VideoJsPlayer} from 'video.js';
import {Director, Logger, View} from '@millicast/sdk'

const Plugin = videojs.getPlugin('plugin');

export class MillicastVideoJsPlugin extends Plugin {

    static defaultOptions: MillicastVideoJsPluginOptions = {
        accountId: undefined,
        streamName: undefined
    };

    private options!: MillicastVideoJsPluginOptions
    private millicastView!: View;

    constructor(player: VideoJsPlayer, options?: MillicastVideoJsPluginOptions) {
        super(player);

        this.options = {
            ...MillicastVideoJsPlugin.defaultOptions,
            ...options
        }
        Logger.setLevel(Logger.WARN)

        //Define callback for generate new token
        const tokenGenerator = () => Director.getSubscriber(this.options.streamName, this.options.accountId)

        //Create a new instance
        this.millicastView = new View(this.options.streamName, tokenGenerator)
        this.millicastView.on('track', (event: RTCTrackEvent) => {
            const v = this.player.tech(true).el() as HTMLVideoElement;
            v.srcObject = event.streams[0];
        })
        player.on("millicast-start", () => {
            player.one("waiting", () => {
                player.addClass('vjs-waiting')
            })
            player.one("playing", () => {
                player.removeClass('vjs-waiting')
            })
            this.connect()
        })
        player.on("millicast-stop", () => {
            this.disconnect()
            const v = this.player.tech(true).el() as HTMLVideoElement;
            v.srcObject = null
        })
    }

    private async connect() {
        try {
            this.player.trigger("waiting")
            const mcOptions = {
                disableVideo: false,
                disableAudio: false,
            }
            // this.millicastView.options = mcOptions // patch to overcome null-ref error after reconnect
            await this.millicastView.connect(mcOptions)
        } catch (e) {
            this.millicastView.reconnect()
        }
    }

    private disconnect() {
        if (this.millicastView) {
            this.millicastView.stop()
        }
    }

    dispose() {
        super.dispose();
    }
}

videojs.registerPlugin('millicast', MillicastVideoJsPlugin);

export interface MillicastVideoJsPluginOptions {
    accountId?: string;
    streamName?: string;
}
