import {Component, Vue} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import {MetadataModule} from "@/store/MetadataModule";

@Component
export default class MetadataEventUtil extends Vue {
    metadataModule = getModule(MetadataModule, this.$store)

    get hasArena() {
        return this.metadataModule.event && this.metadataModule.event.arena
    }

    get hasTest() {
        return this.metadataModule.event && this.metadataModule.event.test
    }
}