






import {Component, Vue, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import OverlayConfigMixin from "@/components/overlay/OverlayConfigMixin";

@Component
export default class Background extends mixins(OverlayConfigMixin, Vue) {
  mounted() {
    this.updateBackground()
  }

  @Watch("applicationModule.config")
  onConfigChanged() {
    this.updateBackground()
  }

  updateBackground() {
    const bg = this.metadataParameters["background"]

    const appElement = document.querySelector("#app") as HTMLElement;
    if (appElement) {
      if (bg) {
        appElement.style.setProperty('background-color', this.chromaClass(bg))
      } else {
        appElement.style.removeProperty('background-color')
      }
    }
  }

  chromaClass(color: string) {
    switch (color.toUpperCase()) {
      case "M":
        return "#ff0090"
      case "B":
        return "#0047bb"
      case "G":
        return "#00b140"
      case "T":
        return "rgba(0,0,0,0)"
      default:
        return "#000000"
    }
  }

}
