import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './styles/index.scss'
import 'webrtc-adapter';
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'
import browserDetect from "vue-browser-detect-plugin";
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import VueFlags from "@growthbunker/vueflags";

Vue.config.productionTip = false

Vue.use(browserDetect)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueResize)
Vue.use(VueFlags, {
    iconPath: '/flags',
});


new Vue({
    store,
    render: h => h(App)
}).$mount('#app')
