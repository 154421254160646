



























import {Component, Vue} from 'vue-property-decorator';
import SponsorBar from "@/components/SponsorBar.vue";
import MetadataEventUtil from "@/components/overlay/MetadataEventUtil";
import {mixins} from "vue-class-component";
import OverlayConfigMixin from "@/components/overlay/OverlayConfigMixin";

@Component({
  components: {SponsorBar}
})
export default class CaptionBase extends mixins(MetadataEventUtil,OverlayConfigMixin, Vue) {

}

