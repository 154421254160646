































import {Component, Vue} from 'vue-property-decorator';
import {ApplicationModule} from "@/store/ApplicationModule";
import {getModule} from "vuex-module-decorators";

@Component
export default class ApplicationStatusOverlay extends Vue {
  private applicationModule = getModule(ApplicationModule, this.$store)

  get isLoading() {
    return this.applicationModule.loading
  }

  get isNotFound() {
    return this.applicationModule.status == "NOT_FOUND"
  }

  get isError() {
    return this.applicationModule.status == "ERROR"
  }

  get isOnlineError() {
    return this.applicationModule.status == "ONLINE_ERROR"
  }

  get isOffline() {
    return this.applicationModule.status == "OFFLINE"
  }

  get show() {
    return this.applicationModule.status !== "ONLINE" || this.isLoading
  }

}
