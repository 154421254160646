








































import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import DressageEventUtil from "@/components/overlay/dressage/DressageEventUtil";

@Component
export default class CmhDressage extends mixins(DressageEventUtil, Vue) {
  mounted() {
    this.installEventHandler()
  }

  beforeDestroy() {
    this.uninstallEventHandler()
  }
}

