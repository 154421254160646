export interface PlayerConfig {
    id: string;
    online: boolean;
    variant?: string;
    context: CompetitionContext;
    video: VideoConfig;
    metadata: MetadataConfig;
    advertising?: AdvertisingConfig;
}

export interface CompetitionContext {
    competition: Competition;
    arena: Arena;
}

export interface VideoConfig {
    type: "NONE" | "MILLICAST_WEBRTC";
    options: VideoConfigOptions;
}

export type VideoConfigOptions = undefined | MillicastOptions

export interface DefaultVideoOptions {
    poster?: string;
}

export interface MillicastOptions extends DefaultVideoOptions {
    accountId: string;
    streamName: string;
}

export interface MetadataConfig {
    type: "NONE" | "EMBEDDED_SCALED" | "EMBEDDED_RESPONSIVE" | "EXTERNAL";
    broker?: MetadataBrokerConfig;
    delay?: number;
    options: undefined | EmbeddedScaledOptions;
}

export interface MetadataBrokerConfig {
    url: string;
    user: string;
    password: string;
    vhost: string;
}

export interface EmbeddedOptions {
    theme: string;
    layout: EmbeddedLayoutName;
    assets?: Array<string>;
    parameters?: any;
}

export interface EmbeddedScaledOptions extends EmbeddedOptions {
    referenceWidth: number;
    referenceHeight: number;
}

export interface AdvertisingConfig {
    enabled: boolean;
    urls: Array<string>;
}

export interface MetadataEvent {
    type: MetadataEventType;
    competition: Competition;
    arena?: Arena;
    test?: Test;
    payload: MetadataEventPayload;
}

export type MetadataEventType = "DRESSAGE" | "SHOW_JUMPING" | "PRESENTATION"

export type MetadataEventPayload = string | JumpingTimingPayload | DressagePayload | PresentationPayload

export interface PresentationPayload {
    practitioner: HorseOnly;
}

export interface HorseOnly {
    uuid: string;
    competitionNumber?: string;
    auction: boolean;
    horse: HorseData;
    father?: HorseData;
    mother?: HorseData;
    fatherOfFather?: HorseData;
    motherOfFather?: HorseData;
    fatherOfMother?: HorseData;
    motherOfMother?: HorseData;
}

export interface HorseData {
    // id: string;
    name: string;
    detail?: string;
    // sportName: string;
    // verified: boolean;
    // pony: boolean;
    // gender?: string;
    // color?: string;
    // yearOfBirth?: number;
    // ueln: string;
    // fei?: string;
    // description?: string;
    // studbook?: string;
    // studbookNumber?: string;
    // withersHeight?: WithersHeight;
    // breeder?: PersonName;
    // owner?: PersonName;
}

export interface WithersHeight {
    id: string;
    name: string;
    value: string;
}

export interface PersonName {
    id: string;
    name: string;
    nationality: string;
}

export interface DressagePayload {
    combination: IndividualCombination;
}

export interface JumpingTimingPayload {
    arenaStatus: JumpingArenaActivityStatus;
    combination: IndividualCombination;
    clock?: number;
    time?: number;
    totalTime?: number;
    additionalTime?: number;
    totalErrors?: number;
    obstacleErrors?: number;
    timeErrors?: number;
    finishStatus?: TestResultStatus;
    timekeeperState?: TimekeeperState;
    timekeeperRunState?: TimekeeperRunState;
    timekeeperAction?: TimekeeperActionType;
}

export enum TestResultStatus {
    FINISHED = 'FINISHED',
    ELIMINATED = 'ELIMINATED',
    RETIRED = 'RETIRED',
    WITHDRAWN = 'WITHDRAWN',
    DISQUALIFIED = 'DISQUALIFIED',
    NOT_STARTED = 'NOT_STARTED'
}

export enum JumpingArenaActivityStatus {
    DRAGGING = "DRAGGING",
    BUILDING = "BUILDING",
    EXPLORATION = "EXPLORATION",
    CLOSED = "CLOSED",
    PAUSE = "PAUSE",
    RUNNING = "RUNNING"
}

export enum TimekeeperState {
    WAITING = 'WAITING',
    COUNTDOWN = 'COUNTDOWN',
    RUNNING = 'RUNNING',
    FINISHED = 'FINISHED'
}

export enum TimekeeperRunState {
    PAUSE = 'PAUSE',
    RUN = 'RUN',
    FINISH = 'FINISH'
}


export enum TimekeeperActionType {
    RESET = 'RESET',
    UPDATE = 'UPDATE',
    SELECTED = 'SELECTED',
    STARTED = 'STARTED',
    HALTED = 'HALTED',
    CONTINUED = 'CONTINUED',
    FINISHED = 'FINISHED',
    CONTINUE_NEXT_PHASE = 'CONTINUE_NEXT_PHASE'
}

export interface IndividualCombination {
    competitionNumber?: string;
    position?: number;
    rider: Rider;
    club?: Club;
    horse: Horse;
}

export interface Rider {
    firstName: string;
    lastName: string;
    nationality: string;
}

export interface Club {
    name: string;
}

export interface Horse {
    name: string;
    father: string;
    mother: string;
    fatherOfMother: string;
    detail?: string;
}

export interface Competition {
    uuid: string;
    name: string;
}

export interface Test {
    uuid: string;
    name: string;
}

export interface Arena {
    uuid: string;
    name: string;
}

export type EmbeddedLayoutName =
    "NONE"
    | "BASE"
    | "JUMPING_PARTICIPANT"
    | "DRESSAGE_PARTICIPANT"
    | "PRESENTATION_PARTICIPANT"
    | "CMH_JUMPING"
    | "CMH_DRESSAGE"

export type MetadataEventHandler = (event: MetadataEvent) => void
